<template>
  <div :class="className">
    <vue-select
      v-if="isloaded"
      v-model="selectedValue"
      :options="options"
      close-on-select
      :label-by="labelBy"
      :searchable="searchable"
      :placeholder="placeholder"
      @selected="handleSelect"
      @removed="handleRemoved"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { ref } from "vue";
import VueSelect from "vue-next-select";
export default {
  components: { VueSelect },
  name: "DropDown",
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
    options: {
      type: Array,
    },
    labelBy: {
      type: String,
      default: "label",
    },
    valueBy: {
      type: String,
      default: "value",
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    className: {
      type: [String, Array],
    },
    inputClass: {
      type: [String, Array],
    },
    error: {
      type: String,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "title",
    },
    iconKey: {
      type: String,
      default: "icon",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "validate", "save"],
  data() {
    return {
      isloaded: false,
      selectedValue: "",
      cutomizedOptions: [],
    };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (!this.options || this.options.length == 0) return;
        this.cutomizedOptions = [];
        for (let i = 0; i < this.options.length; i++) {
          let item = this.options[i];
          if (newVal && newVal.toString() == item.value.toString()) {
            this.selectedValue = ref(item);
            break;
          }
        }
      },
      immediate: true,
    },
    options: {
      handler(newOptions) {
        if (!newOptions || newOptions.length == 0) return;
        for (let i = 0; i < newOptions.length; i++) {
          let item = newOptions[i];
          if (
            this.modelValue &&
            this.modelValue.toString() == item.value.toString()
          ) {
            this.selectedValue = ref(item);
            break;
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    hasSlot() {
      return this.$slots.default;
    },
    displayOptions() {
      // if (this.searchTerm.length > 0) {
      //   return this.options.reduce((result, option) => {
      //     if (
      //       option[this.titleKey]
      //         .toLowerCase()
      //         .includes(this.searchTerm.toLowerCase())
      //     ) {
      //       return [...result, option];
      //     }
      //     return result;
      //   }, []);
      // }
      return this.options;
    },
  },
  created() {
    if (this.modelValue) {
      if (!(this.modelValue instanceof Object)) {
        const index = _.findIndex(
          this.options,
          (option) => option.id === this.modelValue
        );
        this.handleSelect(this.options[index]);
      } else {
        this.handleSelect(this.modelValue);
      }
    }
  },
  methods: {
    handleOpen() {
      this.listOpened = true;
      this.searchTerm = "";
    },
    handleClose() {
      this.listOpened = false;
      this.handleValidate();
    },
    handleClear() {
      // this.$emit('update:modelValue', null);
    },
    handleChange(option) {
      this.handleSelect(option);
      const lastValue = this.modelValue;

      if (lastValue !== option) this.$emit("save");
    },
    handleSelect(option) {
      if (option) {
        this.$emit("update:modelValue", option["value"]);
        this.$emit("update:modelInvalid", false);
        this.listOpened = false;
      }
    },
    hanldeSearchInput(event) {
      this.searchInput.value = event.target.value;
    },
    handleRemoved() {
      this.$emit("update:modelValue", "");
      this.$emit("update:modelInvalid", true);
    },
    handleBlur() {
      this.$emit("blur");
    },
    handleValidate() {
      this.$emit("validate");
    },
    handleFile(e, setValue) {
      const [file] = e.target.files;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.profilePic = event.target.result;
      };
      reader.readAsDataURL(file);
      this.popupCropImage = true;
      setValue("file", file);
    },
  },
  mounted() {
    this.isloaded = true;
  },
};
</script>

<style>
.vue-select {
  justify-content: center;
  border: none;
  width: 100%;
}
.vue-input input {
  background-color: transparent;
}
.vue-dropdown {
  max-height: 180px;
}
</style>