<template>
  <date-picker
    v-model="data"
    :masks="masks"
    :popover="{ visibility: 'click' }"
    class="wp-48"
  >
    <template #default="{ inputValue, inputEvents, hidePopover }">
      <div
        :class="`inputContainer ${modelInvalid ? 'invalid' : ''} ${className}`"
      >
        <input
          class="input"
          :value="inputValue"
          v-on="inputEvents"
          @keyup.enter="hidePopover"
          placeholder="MM/DD/YYYY"
        />
      </div>
    </template>
  </date-picker>
</template>

<script>
import { DatePicker } from "v-calendar";
import "vue-next-select/dist/index.min.css";
export default {
  name: "TaskDueDate",
  components: { DatePicker },
  emits: ["update:modelValue", "save", "update:modelInvalid"],
  props: {
    modelValue: {
      type: [String, Object],
    },
    className: {
      type: [String, Array],
      default: "wp-100",
    },
    modelInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
    masks: {
      input: "MM/DD/YYYY",
    },
  }),
  watch: {
    data() {
      this.$emit("update:modelValue", this.data);
      this.$emit("update:modelInvalid", false);
      if (this.data && this.data !== this.modelValue) this.$emit("save");
    },
    modelValue: {
      handler(newVal) {
        this.data = newVal;
      },
      immediate: true,
    },
  },
  created() {
    this.data =
      this.modelValue === "No Due Date Configured" ? null : this.modelValue;
  },
  methods: {},
};
</script>

<style scoped></style>
